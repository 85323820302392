import React, { useContext } from "react";
import UserContext from "../../../../context/UserContext/UserContext";
import { Logout, ToastMessage } from "../../../../utils/Helper";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import UserDataTabelContainer from "./UserDataTabelContainer";
import UserCreateFormPopup from "./UserCreateFormPopup";
import Breadcrumb from "../../../../reuseable/Breadcrumb/Breadcrumb";
import {
  SLACE,
  USER,
  USER_LIST,
} from "../../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";

const User = () => {
  const {
    //pagination state variables
    pageSize,
    page,
    setRecord,
    sortOrder,
    sortBy,
    filter,

    //user edit condition state and data object state
    setdialogs,
    setErrors,
    dialog,
    setIsEdit,
    setFormData,
    setFilterDrop,
  } = useContext(UserContext);

  const history = useNavigate();

  /**
   * get user List
   */

  let paramsObj = {
    pageNumber: page,
    recordsPerPage: pageSize,
    sortOrder,
    sortBy,
  };
  if (filter.length) {
    paramsObj["search"] = { name: filter };
  }

  // const [data, setData] = useState();

  const get_User = async (paramsObj) => {
    const q = query(
      collection(db, "gallery"),
      where("parentId", "==", localStorage.getItem("userId"))
    );
    const querySnapshotTemp = await getDocs(q)
    return querySnapshotTemp.docs.map(doc => doc.data());

    // => {
    //   // here data get

    //   // get role from firebase account
    //   console.log("querySnapshot", querySnapshot);
    //   debugger
    //   ToastMessage(200, "Data Retrived.");
    //   return querySnapshot.docs.map(doc => doc.data())

    // }).catch((error) => {
    //   ToastMessage(400, "Please Enter Valid Credentials.");
    // });


    // await getDocs(collection(db, "gallery"))
    //   .then((querySnapshot) => {
    //     // here data get

    //     // get role from firebase account
    //     console.log("querySnapshot", querySnapshot);

    //     ToastMessage(200, "Login Successful.");


    //   }).catch((error) => {
    //     ToastMessage(400, "Please Enter Valid Credentials.");
    //   });


  }

  let { data, refetch, isLoading, error } = useQuery(
    ["getUser", paramsObj],
    () => get_User(paramsObj),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: true,
      keepPreviousData: true,
      refetchOnReconnect: false,
      retry: 3,
      onSuccess: (response) => {

        // console.log("response", response);
        // if (response.status === 200) {
        setRecord(response.length);
        // }
      },
    }
  );

  if (error) {
    const { status, message } = error?.response?.data || error;
    ToastMessage(status, message);
    Logout(history, status);
  }

  const emptyData = () => {
    setFormData({
      name: null,
      phone: null,
      dialCode: { code: "+91", name: "+91" },
      roleId: null,
      email: null,
      image: null,
      assignStores: null,
      password: null,
      confirmPassword: null,
    });
    setFilterDrop((prev) => {
      return { ...prev, storeFilter: "" };
    });
  };

  /**
   * Open Popup and Close Popup User
   */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setdialogs(condition);
      emptyData();
      setErrors({});
    }
  };
  return (
    <>
      <Breadcrumb name={USER} slace={SLACE} name1={USER_LIST} />
      <UserDataTabelContainer
        isLoading={isLoading}
        data={data}
        handleOpenandClosePopup={handleOpenandClosePopup}
        refetch={refetch}
      />
      {dialog && (
        <UserCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
        />
      )}
    </>
  );
};

export default User;
