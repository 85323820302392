import React, { useContext } from "react";
import { maxLengthCheck } from "../utils/Helper";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { MultiSelect } from "primereact/multiselect";
import CssContext from "../context/css_context/CssContext/CssContext";

const Inputs = (props) => {
  const { Label, type, name, value, onChange } = props;
  return (
    <>
      <label for="exampleInputEmail1" className="form-label">
        {Label}
      </label>
      {type === "number" ? (
        <input
          type={type}
          name={name}
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          value={value}
          min={"0"}
          maxLength={10}
          onInput={maxLengthCheck}
          onChange={(e) => onChange(e)}
        />
      ) : (
        <input
          type={type}
          name={name}
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          value={value}
          onChange={(e) => onChange(e)}
        />
      )}
    </>
  );
};

export default Inputs;

export const LoginInput = (props) => {
  const { Label, type, name, value, onChange, maxlangth } = props;
  return maxlangth ? (
    <span className="p-input-icon-left w-full mt-3">
      <i className="pi pi-phone" />
      <InputText
        placeholder={Label}
        type={type}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        onInput={(e) => maxLengthCheck(e, maxlangth)}
        className="p-inputtext p-component w-full md:w-25rem"
        style={{ height: "44px" }}
      />
    </span>
  ) : (
    <span className="p-input-icon-left w-full mt-3">
      <i className="pi pi-lock" />
      <InputText
        placeholder={Label}
        type={type}
        name={name}
        value={value}
        onChange={(e) => onChange(e)}
        className="p-inputtext p-component w-full md:w-25rem"
        style={{ height: "44px" }}
      />
    </span>
  );
};

export const SearchFilterFeild = (props) => {
  const { filter, setFilter, type, placeholder } = props;
  const { darkMode } = useContext(CssContext);
  return (
    <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputText
        placeholder={placeholder}
        type={type}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        className={`w-full ${darkMode ? "dark-input" : ""}`}
        style={{ height: "45px" }}
      />
    </span>
  );
};

export const FormDataFeild = (props) => {
  const {
    label,
    name,
    placeholder,
    value,
    handleChange,
    handleFocusChange,
    type,
    id,
    maxlangth,
    option,
    search,
    dropdown,
    multiDropdown,
    filter,
    multiOption,
    blur,
    disabled,
  } = props;
  const { darkMode } = useContext(CssContext);
  return filter === false ? (
    <div className="flex flex-column gap-2 padding">
      <label
        htmlFor="username"
        className="font-medium text-900"
        style={{ fontSize: "14px" }}
      >
        {label}
      </label>
      <Dropdown
        value={value}
        onChange={(e) => handleChange(e)}
        options={option}
        optionLabel="name"
        name={name}
        placeholder={placeholder}
        className={`w-full p-inputtext-sm ${darkMode ? "dark-input" : ""}`}
        showClear
      />
    </div>
  ) : multiDropdown ? (
    <div className="flex flex-column gap-2 padding">
      <label
        htmlFor="username"
        className="font-medium text-900"
        style={{ fontSize: "14px" }}
      >
        {label}
      </label>
      <MultiSelect
        value={value}
        onChange={(e) => handleChange(e)}
        options={multiOption}
        optionLabel="name"
        name={name}
        filter
        placeholder={placeholder}
        // maxSelectedLabels={3}
        className={`w-full p-inputtext-sm ${darkMode ? "dark-input" : ""}`}
        onKeyUp={(e) => search(e)}
        style={{ height: "44px" }}
        showClear
      />
    </div>
  ) : option && dropdown ? (
    <div className="flex flex-column gap-2 padding">
      <label
        htmlFor="username"
        className="font-medium text-900"
        style={{ fontSize: "14px" }}
      >
        {label}
      </label>
      <Dropdown
        value={value}
        onChange={(e) => handleChange(e)}
        options={option}
        optionLabel="name"
        name={name}
        placeholder={placeholder}
        filter
        className={`w-full p-inputtext-sm ${darkMode ? "dark-input" : ""}`}
        onInput={(e) => search(e)}
        showClear
      />
    </div>
  ) : maxlangth ? (
    <div className="flex flex-column gap-2 padding">
      <label
        htmlFor="username"
        className="font-medium text-900"
        style={{ fontSize: "14px" }}
      >
        {label}
      </label>
      <InputText
        id={id}
        type={type}
        name={name}
        aria-describedby="username-help"
        className={`p-inputtext-sm ${darkMode ? "dark-input" : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e)}
        onInput={(e) => maxLengthCheck(e, maxlangth)}
      />
    </div>
  ) : blur ? (
    <div className="flex flex-column gap-2 padding">
      <label
        htmlFor="username"
        className="font-medium text-900"
        style={{ fontSize: "14px" }}
      >
        {label}
      </label>
      <InputText
        id={id}
        type={type}
        name={name}
        aria-describedby="username-help"
        className={`p-inputtext-sm ${darkMode ? "dark-input" : ""}`}
        placeholder={placeholder}
        value={value}
        onBlur={() => handleFocusChange()}
        onChange={(e) => handleChange(e)}
      />
    </div>
  ) : (
    <div className="flex flex-column gap-2 padding">
      <label
        htmlFor="username"
        className="font-medium text-900"
        style={{ fontSize: "14px" }}
      >
        {label}
      </label>
      <InputText
        id={id}
        type={type}
        name={name}
        aria-describedby="username-help"
        className={`p-inputtext-sm ${darkMode ? "dark-input" : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e)}
        disabled={disabled}
        style={{ color: "black", fontWeight: disabled ? 900 : "normal" }}
      />
    </div>
  );
};
export const FormDataFeildTextArea = (props) => {
  const { label, name, placeholder, value, handleChange, type } = props;
  const { darkMode } = useContext(CssContext);
  return (
    <div className="flex flex-column gap-2 padding">
      <label
        htmlFor="username"
        className="font-medium text-900"
        style={{ fontSize: "14px" }}
      >
        {label}
      </label>
      <InputTextarea
        id="username"
        autoResize
        type={type}
        name={name}
        aria-describedby="username-help"
        className={`p-inputtext-sm ${darkMode ? "dark-input" : ""}`}
        placeholder={placeholder}
        value={value}
        onChange={(e) => handleChange(e)}
        rows={5}
        cols={30}
      />
    </div>
  );
};
