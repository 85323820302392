export const SLACE = "/";
export const GUEST_HOUSE = "Guest House";
export const GUEST_HOUSE_MASTER = "Guest House";
export const DASHBOARD = "Dashboard";
export const E_COMMERCE = "E-commerce";
export const USER = "User";
export const USER_LIST = "User List";
export const BRAND = "Brand";
export const FEATURE = "Feature";
export const STORE = "Store";
export const DETAILS_PAGE = "Details Page";
export const ORDER_SETTING_ = "Global Setting";
export const ORDER_SETTING_LIST = "Global Setting List";
export const STORE_LIST = "Store List";
export const DETAILS_PAGE_LIST = "Details Page List";
export const BRAND_LIST = "Brand List";
export const FEATURE_LIST = "Feature List";
export const PRODUCT = "Products";
export const PRODUCT_LIST = "Product List";
export const PRODUCT_TAG = "Product Tag List";
export const PRODUCT_TAG_ADD = "Product Tag Add";
export const PRODUCT_STOCK_LIST = "Stock List";
export const CATEGORY_ = "Category";
export const BANNER_SLIDER_ = "Sliders";
export const BANNER_SLIDER_ADD = "Slider Add";
export const RIDER_ADD = "Rider Add";
export const CATEGORY_LIST = "Category List";
export const CATEGORY_ADD_ = "Category Add";
export const PRODUCT_ADD = "Product Add";
export const ROOM_CATEGORY = "Room Category";
export const ROOM_CATEGORY_LIST = "Room Category List";
export const GUEST_MASTER_LIST = "Guest List";
export const GUEST_MASTER_ADD = "Guest Add";
export const RIDER_ = "Riders";
export const ORDER_ = "Orders";

/**
 * sidebar title and items name
 */

//title
export const DASHBOARD_TITLE = "DASHBOARD";
export const USER_TITLE = "User";
export const BRAND_TITLE = "Brand";
export const STORE_TITLE = "Store";
export const DETAILS_PAGE_TITLE = "Details Page";
export const ORDER_SETTING = "Global Setting";
export const FEATURE_ = "Feature";
export const INQUIRY = "Inquiry";
export const ORDERS = "Orders";
export const PRODUCTS = "Products";
export const CATEGORY = "Category";
export const BANNER_SLIDER = "Banner / Slider";
export const GUEST_HOUESE_TITLE = "GUEST HOUSE";
export const ROOMS_TITLE = "ROOMS";
export const RIDER = "Rider";

//items
export const DASHBOARD_ITEM = "Dashboard";
export const USER_ITEM = "User List";
export const BRAND_ITEM = "Brand List";
export const STORE_ITEM = "Store List";
export const DETAILS_PAGE_ITEM = "Details Page List";
export const ORDERS_ITEM = "Orders List";
export const PRODUCTS_ITEM = "Products List";
export const PRODUCTS_ADD = "Products Add";
export const PRODUCTS_STOCK_ITEM = "Stock List";
export const PRODUCTS_STOCK__ADD = "Stock Add";
export const PRODUCTS_TAG_ITEM = "Tag List";
export const PRODUCTS_TAG_ADD = "Tag Add";
export const CATEGORY_ITEM = "Category List";
export const BANNER_SLIDER_ITEM = "Sliders List";
export const BANNER_BANNER_LIST = "Banners List";
export const ORDER_sETTING_LIST = "Global Setting List";
export const FEATURE_ITEM = "Feature List";
export const INQUIRY_ITEM = "Inquiry List";
export const CATEGORY_ADD = "Category Add";
export const GUEST_HOUESE_MASTER_ITEM = "Guest House";
export const GUEST_ITEM = "Guest";
export const GUEST_LIST_ITEM = "Guest List";
export const GUEST_ADD_ITEM = "Guest Add";
export const ROOM_CATEGORY_ITEM = "Room Category";
export const ROOM_FACILITY_ITEM = "Room Facilities";
export const ROOMS_ITEM = "Rooms";
export const ROOM_ALLOCATION = "Room Allocat";
export const RIDER_LIST = "Rider List";
export const RIDER_ITEM = "Riders List";
export const ORDER_ITEM = "Orders List";
