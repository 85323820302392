import React, { useState } from "react";
import OrderSettingContext from "./OrderSettingContext";

const OrderSettingState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filter, setFilter] = useState("");

  /**order setting edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [id, setId] = useState(null);
  const [formData, setFormData] = useState({
    taxLabel: null,
    numberOfImagesPerProduct: null,
    defaultTimeZone: null,
    defaultDateFormat: null,
    companyName: null,
    companyLogo: null,
    image: null,
    address: null,
    email: null,
    mobile: null,
    whatsapp: null,
    city: null,
    state: null,
    country: null,
    pincode: null,
    taxID: null,
    maxOrderQty: null,
    minOrderAmount: null,
    deliveryCharge: null,
    currency: null,
    adminPercentage: null,
    skipLogin: false,
    stockShow: false,
    defaultSortListing: { code: "DESC", name: "DESC" },
    androidKey: null,
    iosKey: null,
    invoiceTerms: null,
    orderOnWhatsapp: null,
    websiteTitle: null,
    seoTitle: null,
    seoDesc: null,
    seoKeyword: null,
    googleTag: null,
    facebookTag: null,
    schema: null,
    facebook: null,
    x: null,
    youtube: null,
    playStoreLink: null,
  });
  const [filterCurrency, setFilterCurrency] = useState("");
  const [filterDefaultSort, setFilterDefaultShort] = useState("");
  const [filterDefaultTime, setFilterDefaultTime] = useState("");
  const [filterDefaultDate, setFilterDefaultDate] = useState("");
  const [filterDefaultMobile, setFilterDefaultMobile] = useState("");
  const [filterDefaultWhatsapp, setFilterDefaultWhatsapp] = useState("");
  const [yes, setYes] = useState(false);
  const [errors, setErrors] = useState({});

  const [crop, setCrop] = useState();
  const [completedCrop, setCompletedCrop] = useState();
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState();
  const [base64, setBase64] = useState("");
  const [imageModal, setImageModal] = useState(false);
  const [imageType, setImageType] = useState("");

  return (
    <OrderSettingContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        id,
        setId,
        formData,
        setFormData,
        yes,
        setYes,
        errors,
        setErrors,
        filterCurrency,
        setFilterCurrency,
        filterDefaultTime,
        setFilterDefaultTime,
        filterDefaultDate,
        setFilterDefaultDate,
        filterDefaultMobile,
        setFilterDefaultMobile,
        filterDefaultWhatsapp,
        setFilterDefaultWhatsapp,
        filterDefaultSort,
        setFilterDefaultShort,

        crop,
        setCrop,
        completedCrop,
        setCompletedCrop,
        scale,
        setScale,
        rotate,
        setRotate,
        aspect,
        setAspect,
        base64,
        setBase64,
        imageModal,
        setImageModal,
        imageType,
        setImageType,
      }}
    >
      {props.children}
    </OrderSettingContext.Provider>
  );
};

export default OrderSettingState;
