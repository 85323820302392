import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import UserContext from "../../../../context/UserContext/UserContext";
import { TIME_ZONE, optionsDialCode } from "../../../../constants";
import { useMutation } from "react-query";
import {
  DELETE_USER,
  Enable_Disable_User,
} from "../../../../Apis/userApi/UserApi";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";

const UserDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
    tempFolder,
    setTempFolder,
    setFolder,
    folder,
    favoritePhotos,
    setFavoritePhotos
  } = useContext(UserContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  /**
   * get user Details
   */
  const { mutate: userDetails, isLoading: isLoadingUserDetails } = useMutation(

    (userId) => Get_Details_User(userId)

  );

  const Get_Details_User = async (userId) => {

    const q = query(
      collection(db, "gallery"),
      where("userID", "==", userId)
    );
    const querySnapshotTemp = await getDocs(q)

    querySnapshotTemp.docs.map(doc => doc.data())

    let data = querySnapshotTemp.docs.map(doc => doc.data())?.[0]
    let [dialCode] = optionsDialCode.filter(
      (elem) => elem.code === data.dialCode
    );

    setFormData(data)

    let TempFolders = Object.keys(data).filter((elem) => elem.includes(" Photos"))

    let arrFolders = [];
    TempFolders.map((folder, i) => {
      let folders = {
        index: i,
        folderName: folder,
        folderKey: data[folder],
      }
      arrFolders.push(folders);
    })

    setTempFolder(arrFolders)
    if (data?.FavoritePhotos) {
      setFavoritePhotos(data?.FavoritePhotos);
    }
    setFolder([{
      index: 0,
      folderName: null,
      folderKey: null,
    }])
    setdialogs(true);
    return querySnapshotTemp.docs.map(doc => doc.data());
  }


  const GetUserDetails = (userId) => {
    userDetails(userId);
    setOrgId(userId);
    setIsEdit(false);
  };

  /**
   * delete user
   */
  const { mutate: userDelete, isLoading: isLoadingDelete } = useMutation(
    (userId) => DELETE_USER(userId, refetch)
  );

  const DeleteUser = async (userId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      userDelete(userId);
    }
  };

  /**
   * enable / disable user
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_User(formData, refetch)
  );

  const handleUserStatusChange = (userId, status) => {
    let formDate = {
      userId: userId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.userId === userId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (
        <>
          <span onClick={() => GetUserDetails(params.row.userID)}>
            {params.row.name ? params.row.name : "--"}
          </span>

        </>),
    },
    {
      flex: 0.1,
      minWidth: 250,
      field: "email",
      headerName: "Email",
      renderCell: (params) => (params.row.email ? params.row.email : "--"),
    },
    {
      flex: 0.1,
      minWidth: 120,
      field: "phone",
      headerName: "Phone",
      renderCell: (params) =>
        params.row.mobileno ? params.row.mobileno : "--",
    },
    // {
    //   flex: 0.1,
    //   minWidth: 100,
    //   field: "status",
    //   headerName: "Status",
    //   renderCell: (params, index) => (
    //     <div className="flex justify-content-center">
    //       <InputSwitch
    //         checked={params.row.isActive === true}
    //         onChange={() =>
    //           handleUserStatusChange(params.row.userId, params.row.isActive)
    //         }
    //       />
    //     </div>
    //   ),
    // },
    {
      flex: 0.1,
      minWidth: 180,
      field: "createdDate",
      headerName: "createdDate",
      sortable: false,
      renderCell: (params) => (
        <span style={{ color: (moment().diff(params.row.createdDate.toDate(), 'months') ? '#ff0000' : '#00ff00') }}>
          {moment(params.row.createdDate.toDate())
            .utcOffset(TIME_ZONE)
            .format("DD MMM, YYYY hh:mm:ss A")
          }
        </span>
      )
    },
    // {
    //   flex: 0.1,
    //   minWidth: 100,
    //   field: "actions",
    //   headerName: "Actions",
    //   sortable: false,

    //   renderCell: (params) => (
    //     <>
    //       <div style={{ paddingRight: "5px" }}>
    //         <Tooltip title="Edit">
    //           <IconButton
    //             aria-label="edit"
    //             onClick={() => GetUserDetails(params.row.userId)}
    //           >
    //             <Edit style={{ color: "#7366ff" }} />
    //           </IconButton>
    //         </Tooltip>
    //       </div>
    //       <div style={{ paddingRight: "5px" }}>
    //         <Tooltip title="Delete">
    //           <IconButton
    //             aria-label="delete"
    //             onClick={() => DeleteUser(params.row.userId)}
    //           >
    //             <Delete style={{ color: "red" }} />
    //           </IconButton>
    //         </Tooltip>
    //       </div>
    //     </>
    //   ),
    // },
  ];
  return (
    <Fragment>
      <IsLoadingResponse isLoading={isLoadingUserDetails || isLoadingDelete} />
      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={record}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.userId}
        addButton={false}
        search={false}
      />
    </Fragment>
  );
};

export default UserDataTabelContainer;
