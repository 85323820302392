import React, { Fragment, useContext, useEffect, useState } from "react";
import AWS from 'aws-sdk';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from "../../../../firebase";
import { FormDataFeild } from "../../../../reuseable/Inputs";
import { useMutation } from "react-query";

import {
  Put_Update_User,
  Upload_Image,
} from "../../../../Apis/userApi/UserApi";
import UserContext from "../../../../context/UserContext/UserContext";
import { Dialog } from "primereact/dialog";
import { Toast } from 'primereact/toast';
import { FileUpload } from 'primereact/fileupload';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { IsLoadingResponse, ToastMessage } from "../../../../utils/Helper";
import "cropperjs/dist/cropper.css";
import {
  Email,
  Name,
  Phone,
  Submit,
  PasswordLabel,
  TYPE_FELDS,
  TypeNumber,
  CreateUser,
  DialCode,
  optionsDialCode,
  FileType,
  Alt,
  FolderName,
  UserDetails,
} from "../../../../constants";
import { TabView, TabPanel } from 'primereact/tabview';
import { Col, Form, Row } from "react-bootstrap";
import { Card, CardBody } from "reactstrap";
import {
  createUserSchema,
} from "../../../../utils/validationSchema";
import CropImagePopup from "../../../CropImagePopup";
import { get_Store } from "../../../../Apis/storeApi/StoreApi";
import { Timestamp, collection, doc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { UploadCloud } from "react-feather";
import { S3 } from "aws-sdk";
import { useRef } from "react";
import UserImagesDataTabelContainer from "./UserImagesDataTabelContainer";
import { CreateButtonOutline } from "../../../../reuseableComponents/buttons";
import JSZip from "jszip";
import UserAlbumDataTabelContainer from "./UserAlbumDataTabelContainer";

const UserCreateFormPopup = (props) => {
  const {
    setFormData,
    orgId,
    isEdit,
    setdialogs,
    formData,
    dialog,
    errors,
    setErrors,
    crop,
    setCrop,
    completedCrop,
    setCompletedCrop,
    scale,
    rotate,
    aspect,
    folder,
    setFolder,
    tempFolder,
    setTempFolder,
    favoritePhotos,
    setFavoritePhotos
  } = useContext(UserContext);
  const { handleOpenandClosePopup, refetch, emptyData } = props;
  const [dialogModal, setdialogsModal] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const [albumPhotosState, setAlbumPhotosState] = useState([]);




  const bucketName = process.env.REACT_APP_WASABI_BUCKET_NAME;
  const s3 = new AWS.S3({
    region: process.env.REACT_APP_WASABI_REGION,
    endpoint: process.env.REACT_APP_WASABI_BUCKET_ENDPOINT,
    credentials: {
      accessKeyId: process.env.REACT_APP_WASABI_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_WASABI_ACCESS_KEY
    }
  });
  AWS.config.update({
    region: process.env.REACT_APP_WASABI_REGION,
    accessKeyId: process.env.REACT_APP_WASABI_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_WASABI_ACCESS_KEY,
  });

  /**
   *
   * @param {event } e
   * create User & update User
   */

  const { mutate: createUser, isLoading: createUserLoader } = useMutation(
    (userId) => addData(userId),
    {
      onSuccess: (response) => {
        // if (response.status === 200) {
        //   refetch();
        //   setdialogs(false);
        //   emptyData();
        //   setErrors({});
        //   ToastMessage(response.status, response.data.message);
        // }
      },
    }
  );

  const { mutate: updateUser, isLoading: updateUserLoader } = useMutation(
    (formData) => Put_Update_User(formData, orgId),
    {
      onSuccess: (response) => {
        if (response.status === 200) {
          refetch();
          setdialogs(false);
          emptyData();
          setErrors({});
          ToastMessage(response.status, response.data.message);
        }
      },
    }
  );

  const addData = async (userId) => {
    // auth.updateCurrentUser(originalUser)

    try {

      let value = {
        ...formData,
        userID: userId,
        mobileno: formData?.dialCode?.code + formData.phone,
        password: formData.phone,
        createdDate: Timestamp.fromDate(new Date()),
        DownloadFolder: {},
        FavoritePhotos: [],
        AlbumPhotos: [],
        FolderPhotos: {},
        userType: "Standard"
      };

      folder?.map((elem, i) => {

        if (elem.folderName) {
          value[elem.folderName + " Photos"] = `${localStorage.getItem("userName")}/${formData?.name}_${userId}/${userId}${elem.folderName}/T_Photos`

          var params = {
            Bucket: bucketName,
            Key: `${localStorage.getItem("userName")}/${formData?.name}_${userId}/${userId}${elem.folderName}/T_Photos/`,
            Body: `${elem.folderName}`,
          };

          s3.upload(params, function (err, data) {
            if (err) {
              ToastMessage(400, "Failed to create folders.");
            } else {
              // console.log("Successfully created a folder on S3");
            }
          });
        }
        return 0
      })

      var params = {
        Bucket: bucketName,
        Key: `${localStorage.getItem("userName")}/${formData?.name}_${userId}/${userId}AlbumPhotos/T_Photos/`,
        Body: `AlbumPhotos`,
      };
      s3.upload(params, function (err, data) {
        if (err) {
          ToastMessage(400, "Failed to create folders.");
        } else {
          // console.log("Successfully created a folder on S3");
        }
      });


      const ref = doc(db, "gallery", userId);



      // console.log("firebase object", value)
      await setDoc(ref, value).then(() => {
        handleOpenandClosePopup(false)
        ToastMessage(200, "User Created Successfully.");
      }).catch((error) => {
        ToastMessage(400, error.message);
      });


      handleOpenandClosePopup(false)
      // ToastMessage(200, "User Data Entred Successfully.");
    } catch (error) {
      ToastMessage(400, error.message);
    }
  }





  const handleUserCreateAndUpdate = async (e) => {
    e.preventDefault();
    try {
      let schema = createUserSchema();
      await schema.validate(formData, { abortEarly: false });

      let originalUser = auth.currentUser
      await createUserWithEmailAndPassword(auth, formData.email, formData.phone).then(function (value) {

        let authToken = value.user.accessToken;
        let role = value.user.accessToken;
        let userId = value.user.uid;
        let loginName = value.user.email;

        // setUserId(userIdTemp)
        createUser(userId)
      }).catch(error => {
        debugger
        console.log('error', error.message);
        ToastMessage(400, error.message);
      });


    } catch (error) {
      const validationErrors = {};
      error?.inner?.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
        inputField[0].focus();
      }
    }
  };

  const [progress, setProgress] = useState([]);

  const uploadFile = async (file, fileName, totalFiles, position, files) => {

    // console.log('Uploading file', tempFolder[activeIndex].folderKey + fileName);
    let userId = formData.userID
    let totalAmountSize = totalSize

    let params = {
      Bucket: bucketName,
      Body: file,
    };
    if (tempFolder.length + 1 === activeIndex) {
      params.Key = `${localStorage.getItem("userName")}/${formData?.name}_${userId}/${userId}AlbumPhotos/T_Photos/${fileName}`;
    } else {
      params.Key = `${tempFolder[activeIndex].folderKey}/${fileName}`;
    }


    var upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // console.log(position);
        let tempProgress = progress;
        tempProgress[position] = { value: parseInt((evt.loaded * 100) / evt.total) }
        setProgress(tempProgress);

        if (parseInt((evt.loaded * 100) / evt.total) === 100) {
          // console.log("image number " + params.Body.size + " uploaded successfully");
          // totalAmountSize = totalAmountSize - params.Body.size;
          // console.log("image new size " + totalAmountSize + " uploaded successfully");

          // setTotalSize(totalSize - params.Body.size);
          setTotalSize((totalSize) => {
            // Important: read `state` instead of `this.state` when updating.
            return totalSize - params.Body.size
          });
          // setTotalSize();
          // onTemplateRemoveUpload(file, position);

          // setTotalSize(tempTotalSize - files[position].size);
        }
        // console.log(
        //   "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        // );
      })
      .promise();

    await upload.then((err, data) => {
      // console.log("log files", file);

      // ToastMessage(200, fileName + " uploaded successfully" + file.size)


      // console.log("uploadFiles", albumPhotosState)



      // alert("File uploaded successfully.");
      // setTotalSize(0);
      // fileUploadRef?.current.clear();
      // fileUploadRef?.emptyTemplate();
      if (progress.find((elem) => elem.value < 100) ? false : true) {
        // ToastMessage(200, fileName + " All images upload successfully.")

        console.log("uploadFiles", albumPhotosState)

        toast.current.show({ severity: 'info', summary: 'Success', detail: 'All images upload successfully.', sticky: true });

        setTotalSize(0);
        fileUploadRef?.current.clear();
        let tempProgress = [];
        setProgress(tempProgress);

        if (tempFolder.length + 1 === activeIndex) {
          let value = {
            ...formData,
            AlbumPhotos: albumPhotosState
          }
          const ref = doc(db, "gallery", userId);
          updateDoc(ref, value).then(() => {
            setFormData(value)
          }).catch((error) => {
            ToastMessage(400, error.message);
          });
        }
      }
      getData(currentPage, activeIndex);
    });

  };


  const handleUserAddFolder = async (e) => {
    e.preventDefault();
    // console.log("formData.userId", formData.userId);
    let userId = formData.userID
    let value = {};

    folder?.map((elem, i) => {

      if (elem.folderName) {
        value[elem.folderName + " Photos"] = `${localStorage.getItem("userName")}/${formData.name}_${userId}/${userId}${elem.folderName}/T_Photos`

        var params = {
          Bucket: bucketName,
          Key: `${localStorage.getItem("userName")}/${formData.name}_${userId}/${userId}${elem.folderName}/T_Photos/`,
          Body: `${elem.folderName}`,
        };

        s3.upload(params, function (err, data) {
          if (err) {
            ToastMessage(400, "Failed to create folders.");
          } else {
          }
        });
      }
      return 0
    })

    const ref = doc(db, "gallery", userId);



    // console.log("firebase object", value)
    await updateDoc(ref, value).then(() => {
      handleOpenandClosePopup(false)
      ToastMessage(200, "Folders Created Successfully.");
    }).catch((error) => {
      ToastMessage(400, error.message);
    });

  }

  const { mutate: uploadImage } = useMutation((file) =>
    Upload_Image(file, setdialogsModal, setFormData, FileType.USER)
  );

  /**
   *
   * @param {get {value} and {name}} e
   * input value change
   */

  const handleChangeOrgAdmin = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const search = (e) => { };

  const openImageUploadModal = (e) => {
    let reader = new FileReader();
    debugger
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = (_event) => {

      uploadFile(e.target.files[0], e.target.files[0].name);
    };

    setdialogsModal(true);
  };

  /**
   * add and remove extra field
   */
  const handleAdd = (i) => {
    let obj = {
      index: i,
      folderName: null,
      folderKey: null,
    }

    setFolder(prev => [...prev, obj])
  };

  const handleRemove = (i) => {

    let filteredArray = folder.filter(item => item.index !== i)

    setFolder(filteredArray);

    // folder.splice(i, 1);
    // setFolder(...folder, folder)
  };

  const handleChange = (e, index, i) => {

    const { name, value } = e.target;
    let obj = {
      index: index,
      folderName: value,
      folderKey: null,
    }
    setFolder((prevState) => {
      return prevState.map((prevValue, iner) => i === iner ? obj : prevValue);
    });

  }

  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);

  // console.log('fileUploadRef', fileUploadRef);

  const onTemplateSelect = (e) => {
    let _totalSize = 0;
    let tempProgress = progress;
    let files = e.files;
    console.log('files', files);

    let tempVal = albumPhotosState;

    if (tempFolder.length + 1 === activeIndex) {

      files.forEach(element => {
        let Key = `${localStorage.getItem("userName")}/${formData?.name}_${formData?.userID}/${formData?.userID}AlbumPhotos/T_Photos/${element.name}`;
        if (tempVal.length === 0) {

          tempVal.push({
            key: Key,
            value: ""
          });
        } else {
          if (tempVal.find((elem) => elem.key !== `${localStorage.getItem("userName")}/${formData?.name}_${formData?.userID}/${formData?.userID}AlbumPhotos/T_Photos/${element.name}`)) {
            tempVal.push({
              key: Key,
              value: ""
            });
          }
        }

      });



    }

    setAlbumPhotosState(tempVal)

    let i = progress.length;

    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
      tempProgress.push({
        value: 0
      })
      setProgress(tempProgress);
    });

    setTotalSize(_totalSize);
  };


  const onTemplateUpload = (e) => {

    console.log('this is called? ', e.files);

    let _totalSize = 0;

    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);

    console.log('uploading ' + _totalSize);
    toast.current.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
  };

  const onTemplateRemove = (file, callback, index) => {

    console.log("callback.index", index);

    let tempProgress = progress;
    tempProgress.splice(index, 1);
    setProgress(tempProgress);

    setTotalSize(totalSize - file.size);
    callback();
  };

  const onTemplateRemoveUpload = (file, index) => {

    // console.log("callback.index", index);

    // let tempProgress = progress;
    // tempProgress.splice(index, 1);
    // setProgress(tempProgress);

    setTotalSize(totalSize - file.size);
    // callback();
  };

  const onTemplateClear = () => {
    console.log('onTemplateClear');
    setTotalSize(0);
  };

  const invoiceuploadhandler = ({ files }) => {
    const [file] = files;
    const filereader = new FileReader();
    ToastMessage(200, "Upload Started...");
    let i = 0;



    files.forEach(element => {
      uploadFile(element, element.name, files.length, i++, files);
    })
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, uploadButton, cancelButton } = options;
    const value = totalSize / 10000000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : '0 B';

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
        {uploadButton}
        {cancelButton}
        <div className="flex align-items-center gap-3 ml-auto">
          <span>{formatedValue} / 1 GB</span>
          <ProgressBar value={value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div>
      </div>
    );
  };



  const itemTemplate = (file, props) => {
    const { index } = props;
    return (
      <div className="flex align-items-center flex-wrap">
        <div className="flex align-items-center" style={{ width: '40%' }}>
          <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
          <span className="flex flex-column text-left ml-3">
            {file.name}
            <small>{new Date().toLocaleDateString()}</small>
          </span>
        </div>
        <div className="flex align-items-center gap-3 ml-auto">
          <ProgressBar value={progress[index].value} showValue={false} style={{ width: '10rem', height: '12px' }}></ProgressBar>
        </div>
        <Tag value={props.formatSize} severity="warning" className="px-3 py-2" />
        {/* <Button type="button" icon="pi pi-check" className="p-button-outlined p-button-rounded p-button-success ml-auto" onClick={() => onTemplateRemove(file, props.onRemove)} /> */}
        <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => onTemplateRemove(file, props.onRemove, index)} />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex align-items-center flex-column">
        <i className="pi pi-image mt-3 p-5" style={{ fontSize: '5em', borderRadius: '50%', backgroundColor: 'var(--surface-b)', color: 'var(--surface-d)' }}></i>
        <span style={{ fontSize: '1.2em', color: 'var(--text-color-secondary)' }} className="my-5">
          Drag and Drop Image Here
        </span>
      </div>
    );
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState(null)

  const getData = async (pageNumber, tempActiveIndex, continuationToken = undefined) => {
    if (tempFolder.length === tempActiveIndex) {
      try {

        console.log("favorit photo sectioncalle");
        let tempElem = [];
        let k = 0;
        favoritePhotos.forEach((element, i) => {
          if (favoritePhotos[i] !== "") {
            let innerParams = { Bucket: bucketName, Key: favoritePhotos[i] };
            let signedUrl = s3.getSignedUrl('getObject', innerParams);

            const parts = favoritePhotos[i].split('/')

            // Find the last dot (.) in the string
            const lastDotIndex = parts[parts.length - 1].lastIndexOf('.');
            let imageName = "";
            // if (lastDotIndex !== -1) {
            //   // Extract the part of the string before the last dot
            //   imageName = parts[parts.length - 1].substring(0, lastDotIndex);
            // } else {
            // If there is no dot in the string, keep the original string
            imageName = parts[parts.length - 1];
            // }
            let tempData = [];
            tempData['imageId'] = i;
            tempData['imageName'] = imageName;
            tempData['url'] = signedUrl;
            tempData['Key'] = favoritePhotos[i];
            tempElem.push(tempData);
          }
        });
        setData(tempElem)
        // getPhoto(conv_data)
      } catch (error) {
        throw error;
      }

    } else if (tempFolder.length + 1 === tempActiveIndex) {
      try {

        setAlbumPhotosState(formData?.AlbumPhotos);

        console.log("Album photos", formData?.AlbumPhotos);

        console.log("Album photo sectioncalle");
        let tempElem = [];
        let k = 0;


        const q = query(
          collection(db, "gallery"),
          where("userID", "==", formData?.userID)
        );
        const querySnapshotTemp = await getDocs(q)

        querySnapshotTemp.docs.map(doc => doc.data())

        let data = querySnapshotTemp.docs.map(doc => doc.data())?.[0]
        let [dialCode] = optionsDialCode.filter(
          (elem) => elem.code === data.dialCode
        );

        data.AlbumPhotos.forEach((element, i) => {
          if (element.key !== "") {
            let innerParams = { Bucket: bucketName, Key: element.key };
            let signedUrl = s3.getSignedUrl('getObject', innerParams);

            const parts = element.key.split('/')

            // Find the last dot (.) in the string
            const lastDotIndex = parts[parts.length - 1].lastIndexOf('.');
            let imageName = "";
            // if (lastDotIndex !== -1) {
            //   // Extract the part of the string before the last dot
            //   imageName = parts[parts.length - 1].substring(0, lastDotIndex);
            // } else {
            // If there is no dot in the string, keep the original string
            imageName = parts[parts.length - 1];
            // }
            let tempData = [];
            tempData['imageId'] = i;
            tempData['imageName'] = imageName;
            tempData['url'] = signedUrl;
            tempData['Key'] = element.key;
            tempData['comment'] = element.value;
            tempElem.push(tempData);
          }
        });
        setData(tempElem)
        // getPhoto(conv_data)
      } catch (error) {
        throw error;
      }

    } else {
      console.log("Other photo sectioncalle");
      const params = {
        Bucket: bucketName,
        // MaxKeys: pageSize,
        Prefix: tempFolder[tempActiveIndex].folderKey,
        ContinuationToken: continuationToken,
      };
      try {

        const response = await s3.listObjectsV2(params).promise();
        const conv_data = await JSON.parse(JSON.stringify(response.Contents))
        conv_data.forEach((element, i) => {
          let innerParams = { Bucket: bucketName, Key: conv_data[i].Key };
          let signedUrl = s3.getSignedUrl('getObject', innerParams);

          const parts = conv_data[i].Key.split('/')

          // Find the last dot (.) in the string
          const lastDotIndex = parts[parts.length - 1].lastIndexOf('.');
          let imageName = "";
          // if (lastDotIndex !== -1) {
          //   // Extract the part of the string before the last dot
          //   imageName = parts[parts.length - 1].substring(0, lastDotIndex);
          // } else {
          // If there is no dot in the string, keep the original string
          imageName = parts[parts.length - 1];
          // }

          element['imageId'] = i;
          element['imageName'] = imageName;
          element['url'] = signedUrl;
          conv_data[i] = element;
        });
        setData(conv_data.slice(1))
        // getPhoto(conv_data)
        return response;
      } catch (error) {
        throw error;
      }
    }

  }

  const handleTabChanged = (e) => {
    setActiveIndex(e.index);
    getData(currentPage, e.index);
  }

  // Download Zip file
  const downloadZipFile = async (e) => {
    ToastMessage(200, "Zip with all images will be downloaded sortally...");
    const zip = new JSZip();

    for (let i = 0; i < data.length; i++) {
      const element = data[i];
      const slideParams = { Bucket: bucketName, Key: element.Key };
      ;

      // const response = await s3.getObject(slideParams)
      //   , (err, data) => {
      //   if (err) {
      //     console.error('Error downloading file:', err.message);
      //   } else {
      //     const blob = new Blob([data], { type: 'application/image' });
      //     zip.file(element.imageName, blob);
      //   }
      // })
      const response = await fetch(s3.getSignedUrl('getObject', slideParams));
      const blob = await response.blob();
      // const blob = await new Blob([response], { type: 'application/image' });
      zip.file(element.imageName, blob);
    }

    const zipBlob = await zip.generateAsync({ type: 'blob' });

    // Download the zip file
    const zipFileName = 'downloaded_images.zip';
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = zipFileName;
    downloadLink.click();
  }

  useEffect(() => {
    tempFolder[activeIndex]?.folderKey && getData(currentPage, 0)
  }, []);


  const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
  const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined' };
  const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };


  return (
    <Fragment>
      <Dialog
        header={isEdit ? CreateUser : UserDetails}
        className="modal_style"
        visible={dialog}
        position="top"
        onHide={() => handleOpenandClosePopup(false)}
        draggable={false}
        resizable={false}
      >
        <IsLoadingResponse isLoading={createUserLoader || updateUserLoader} />
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>

                {isEdit ? (
                  <Form
                    className="needs-validation"
                    onSubmit={(e) => handleUserCreateAndUpdate(e)}
                  >
                    <Row>
                      <Col md="6 mb-4">
                        <FormDataFeild
                          label={Name}
                          name="name"
                          id="name"
                          type={TYPE_FELDS.text}
                          placeholder={Name}
                          value={formData.name}
                          handleChange={handleChangeOrgAdmin}
                        />
                        {errors.name && (
                          <span className="error-validation">{errors.name}</span>
                        )}
                      </Col>
                      <Col md="6 mb-4" className="d-flex gap-4 ">
                        <div>
                          <FormDataFeild
                            label={DialCode}
                            name="dialCode"
                            id="dialCode"
                            placeholder={DialCode}
                            value={formData.dialCode}
                            handleChange={handleChangeOrgAdmin}
                            dropdown={true}
                            option={optionsDialCode}
                            search={search}
                          />
                          {errors.dialCode && (
                            <span className="error-validation">
                              {errors.dialCode}
                            </span>
                          )}
                        </div>
                        <div style={{ width: "100%" }}>
                          <FormDataFeild
                            label={Phone}
                            name="phone"
                            id="phone"
                            type={TYPE_FELDS.number}
                            placeholder={Phone}
                            value={formData.phone}
                            handleChange={handleChangeOrgAdmin}
                            maxlangth={TypeNumber.PHONE}
                          />
                          {errors.phone && (
                            <span className="error-validation">
                              {errors.phone}
                            </span>
                          )}
                        </div>
                      </Col>
                      <Col md="6 mb-4">
                        <FormDataFeild
                          label={Email}
                          name="email"
                          id="email"
                          type={TYPE_FELDS.email}
                          placeholder={Email}
                          value={formData.email}
                          handleChange={handleChangeOrgAdmin}
                        />
                        {errors.email && (
                          <span className="error-validation">{errors.email}</span>
                        )}
                      </Col>
                      {isEdit && (
                        <Col md="6 mb-4">
                          {/* <FormDataFeild
                            label={PasswordLabel}
                            name="password"
                            id="password"
                            type={TYPE_FELDS.password}
                            placeholder={PasswordLabel}
                            value={formData.password}
                            handleChange={handleChangeOrgAdmin}
                          />
                          {errors.password && (
                            <span className="error-validation">
                              {errors.password}
                            </span>
                          )} */}
                        </Col>
                      )}


                      <Col md="6 mb-4" >
                        {folder?.map((elem, i) => {
                          return (
                            <div className="d-flex gap-2 mb-4" key={i} >
                              <div className="" style={{ width: "100%" }}>
                                <FormDataFeild
                                  label={FolderName}
                                  name={"imgAltText" + i}
                                  id={"imgAltText" + i}
                                  type={TYPE_FELDS.text}
                                  placeholder={FolderName}
                                  value={elem.folderName}
                                  handleChange={(e) => handleChange(e, elem.index, i)}
                                  disabled={isEdit === false && true}
                                />
                              </div>
                              <div
                                className=""
                                style={{ paddingTop: "40px" }}
                              >Photos</div>
                              {folder.length === i + 1 && (
                                <div
                                  className=""
                                  style={{ paddingTop: "28px" }}
                                >
                                  <Button
                                    icon="pi pi-plus"
                                    severity="success"
                                    onClick={() => handleAdd(elem.index + 1)}
                                    type={TYPE_FELDS.button}
                                  />
                                </div>
                              )}

                              {folder.length !== i + 1 && (
                                <div
                                  className=""
                                  style={{ paddingTop: "28px" }}
                                >
                                  <Button
                                    icon="pi pi-trash"
                                    severity="danger"
                                    onClick={() => handleRemove(elem.index)}
                                    type={TYPE_FELDS.button}
                                  />
                                </div>
                              )}
                            </div>
                          )
                        })}

                      </Col>
                    </Row>

                    <Row>
                      <Col md="12 mb-4" className="btn-style">
                        <Button
                          label={Submit}
                          icon="pi pi-check"
                          loading={createUserLoader || updateUserLoader}
                          type={TYPE_FELDS.submit}
                        />
                      </Col>
                    </Row>
                  </Form>
                ) : (
                  <>
                    <Form
                      className="needs-validation"
                      onSubmit={(e) => handleUserAddFolder(e)}
                    >
                      <Row>
                        <Col md="6 mb-4" >
                          {folder?.map((elem, i) => {
                            return (
                              <div className="d-flex gap-2 mb-4" key={i} >
                                <div className="" style={{ width: "100%" }}>
                                  <FormDataFeild
                                    label={FolderName}
                                    name={"imgAltText" + i}
                                    id={"imgAltText" + i}
                                    type={TYPE_FELDS.text}
                                    placeholder={FolderName}
                                    value={elem.folderName}
                                    handleChange={(e) => handleChange(e, elem.index, i)}
                                  />
                                </div>
                                <div
                                  className=""
                                  style={{ paddingTop: "40px" }}
                                >Photos</div>
                                {folder.length === i + 1 && (
                                  <div
                                    className=""
                                    style={{ paddingTop: "28px" }}
                                  >
                                    <Button
                                      icon="pi pi-plus"
                                      severity="success"
                                      onClick={() => handleAdd(elem.index + 1)}
                                      type={TYPE_FELDS.button}
                                    />
                                  </div>
                                )}

                                {folder.length !== i + 1 && (
                                  <div
                                    className=""
                                    style={{ paddingTop: "28px" }}
                                  >
                                    <Button
                                      icon="pi pi-trash"
                                      severity="danger"
                                      onClick={() => handleRemove(elem.index)}
                                      type={TYPE_FELDS.button}
                                    />
                                  </div>
                                )}
                              </div>
                            )
                          })}

                        </Col>

                      </Row>
                      <Row>
                        <Col md="12 mb-4" className="btn-style">
                          <Button
                            label={Submit}
                            icon="pi pi-check"
                            loading={createUserLoader || updateUserLoader}
                            type={TYPE_FELDS.submit}
                          />
                        </Col>
                      </Row>
                    </Form>

                    <div>
                      <Toast ref={toast}></Toast>

                      <Tooltip target=".custom-choose-btn" content="Choose" position="bottom" />
                      <Tooltip target=".custom-upload-btn" content="Upload" position="bottom" />
                      <Tooltip target=".custom-cancel-btn" content="Clear" position="bottom" />

                      <FileUpload ref={fileUploadRef} name="demo[]" multiple accept="image/*" maxFileSize={100000000}
                        onUpload={onTemplateUpload} onSelect={onTemplateSelect} onError={onTemplateClear} onClear={onTemplateClear}
                        headerTemplate={headerTemplate} itemTemplate={itemTemplate} emptyTemplate={emptyTemplate}
                        chooseOptions={chooseOptions} uploadOptions={uploadOptions} cancelOptions={cancelOptions}
                        customUpload={true}
                        uploadHandler={invoiceuploadhandler} />
                    </div>


                    <TabView scrollable activeIndex={activeIndex} onTabChange={(e) => handleTabChanged(e)}>

                      {tempFolder?.map((elem, i) => {
                        return (
                          <TabPanel key={i} header={elem.folderName.replace('Photos', '')} leftIcon="pi pi-folder mr-2">
                            <UserImagesDataTabelContainer
                              isLoading={createUserLoader}
                              data={data}
                              handleOpenandClosePopup={handleOpenandClosePopup}
                              refetch={refetch}
                            />
                          </TabPanel>
                        )
                      })
                      }
                      <TabPanel key={tempFolder.length} header={"Favorite Photos"} leftIcon="pi pi-folder mr-2">

                        <div
                          style={{
                            display: "flex",
                            gap: "8px",
                            flexWrap: "wrap",
                            justifyContent: "end",
                            marginBottom: "10px",
                          }}
                        >
                          <CreateButtonOutline
                            click={downloadZipFile}
                            name={"Download"}
                            variant="primary"
                            icon="pi-download"
                          />
                        </div>
                        <UserImagesDataTabelContainer
                          isLoading={createUserLoader}
                          data={data}
                          handleOpenandClosePopup={handleOpenandClosePopup}
                          refetch={refetch}
                        />
                      </TabPanel>


                      <TabPanel key={tempFolder.length + 1} header={"Album Photos"} leftIcon="pi pi-folder mr-2">

                        <UserAlbumDataTabelContainer
                          isLoading={createUserLoader}
                          data={data}
                          handleOpenandClosePopup={handleOpenandClosePopup}
                          refetch={refetch}
                        />
                      </TabPanel>
                    </TabView>




                  </>
                )}

              </CardBody>
            </Card>
          </Col>
        </Row>
        <CropImagePopup
          dialogModal={dialogModal}
          setdialogsModal={setdialogsModal}
          image={formData?.image}
          aspect={aspect}
          crop={crop}
          setCrop={setCrop}
          completedCrop={completedCrop}
          setCompletedCrop={setCompletedCrop}
          scale={scale}
          rotate={rotate}
          setFormData={setFormData}
          uploadImage={uploadImage}
        />
      </Dialog>
    </Fragment >
  );
};

export default UserCreateFormPopup;
