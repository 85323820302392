import { configureStore } from "@reduxjs/toolkit";
import filterSlice from "./slices/filter";
import headerDropdownSlice from "./slices/headerDropdown/headerDropdown";

export const store = configureStore({
  //reducer :- its use to events handle
  reducer: {
    filter: filterSlice,
    header_dropdown: headerDropdownSlice,
  },
});
