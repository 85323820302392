import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import MainHeader from "../components/mainHeader";
import PrivateRoute from "./PrivateRoute";
import SecondPrivateRoute from "./secondPrivateRoute";
import Dashboard from "../components/pages/Dashboard/Dashboard";
import { PublicAccess, SuperAdminAccess } from ".";
import CssContext from "../context/css_context/CssContext/CssContext";

const AllRoutes = (props) => {
  const { windowSize, setMainDivSidebar, maindivSidebar } = props;
  const divId =
    windowSize.innerWidth <= 991.98
      ? "mobile-tabelContainer-open"
      : maindivSidebar === true
      ? "tabelContainer"
      : maindivSidebar === false
      ? "tabelContainer-static"
      : "";
  const { darkMode } = useContext(CssContext);

  useEffect(() => {
    darkMode
      ? document.body.classList.add("dark-container")
      : document.body.classList.remove("dark-container");
  }, [darkMode]);
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainHeader
            windowSize={windowSize}
            setMainDivSidebar={setMainDivSidebar}
            maindivSidebar={maindivSidebar}
          />
        }
      >
        <Route element={<PrivateRoute />}>
          <Route
            index
            element={
              <div id={divId}>
                <Dashboard />
              </div>
            }
          />
          {SuperAdminAccess.map((elem, i) => {
            return (
              <Route
                path={elem.path}
                element={<div id={divId}>{elem.component}</div>}
                key={i}
              />
            );
          })}
        </Route>
        <Route element={<SecondPrivateRoute />}>
          <Route
            index
            element={
              <div id={divId}>
                <Dashboard />
              </div>
            }
          />
          {SuperAdminAccess.map((elem, i) => {
            return (
              <Route
                path={elem.path}
                element={<div id={divId}>{elem.component}</div>}
                key={i}
              />
            );
          })}
        </Route>
      </Route>
      {PublicAccess.map((elem, i) => {
        return <Route path={elem.path} element={elem.component} key={i} />;
      })}
      {/* <Route
        path={REDIRECT_PATH.FORGOT_PASSWORD}
        element={<ForgotPassword />}
      />
      <Route path={REDIRECT_PATH.RESET_PASSWORD} element={<ResetPassword />} />
      <Route path="*" element={<ErrorPage />} /> */}
    </Routes>
  );
};

export default AllRoutes;
