import { createContext, useContext, useEffect, useState } from "react";
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  updatePassword,
} from "firebase/auth";
import { useNavigate } from "react-router-dom";

import {
  collection,
  getDocs,
  query,
  where,
  updateDoc,
  arrayUnion,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";

import { auth } from "../firebase";

// creating a context for the user
const userContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [data, setData] = useState(null);

  const navigate = useNavigate();

  // handleing sign in requests using firebase auth
  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  const setPassword = (newPassword) => {
    updatePassword(auth.currentUser, newPassword);
  };

  const db_read = async () => {
    if (user) {
      // console.log("Database read", user);
      const q = query(
        collection(db, "gallery"),
        where("userID", "==", user.uid)
      );
      const querySnapshot = await getDocs(q);
      querySnapshot?.forEach(async (doc) => {
        const data = await JSON.parse(JSON.stringify(doc.data()));
        setData(data);
      });
    }
  };

  // const addToFvtPhotos = async (photoUrl) => {
  //   console.log("fvt to add: ", photoUrl);
  //   console.log(Link.FavoritePhotos);

  //   const ref = doc(db, "gallery", user.uid);
  //   await updateDoc(ref, {
  //     FavoritePhotos: arrayUnion(photoUrl),
  //   });
  // };

  // const remFvtPhoto = async (urlToRemove) => {
  //   const ref = doc(db, "gallery", user.uid);
  //   let currentFvtPhotos = Link.FavoritePhotos;
  //   console.log("current ", currentFvtPhotos);

  //   const updatedFvtPhotos = currentFvtPhotos.filter(
  //     (photoUrl) => photoUrl !== urlToRemove
  //   );

  //   await updateDoc(ref, {
  //     FavoritePhotos: updatedFvtPhotos,
  //   });
  // };

  useEffect(() => {
    const getCurrentUser = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => {
      getCurrentUser();
    };
  }, []);

  useEffect(() => {
    user?.uid && db_read();
  }, [user]);

  return (
    <userContext.Provider
      value={{
        signIn,
        user,
        data,
        logout,
        db_read,
        setPassword,
        // addToFvtPhotos,
        // remFvtPhoto,
      }}
    >
      {children}
    </userContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(userContext);
};
