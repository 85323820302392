import React, { useState } from "react";
import DetailsPageContext from "./DetailsPageContext";

const DetailsPageState = (props) => {
  /**pagination states */
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [record, setRecord] = useState(0);
  const [sortOrder, setSortOrder] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filter, setFilter] = useState("");

  /**store edit time condition and data object state */
  const [dialog, setdialogs] = useState(false);
  const [isEdit, setIsEdit] = useState(true);
  const [id, setId] = useState(null);
  const [formData, setFormData] = useState({
    title: "",
    pageUrl: "",
    seoDesc: "",
    seoTitle: "",
    seoKeyword: "",
    description: "",
    order: "",
  });
  const [yes, setYes] = useState(false);
  const [errors, setErrors] = useState({});

  return (
    <DetailsPageContext.Provider
      value={{
        pageSize,
        setPageSize,
        page,
        setPage,
        record,
        setRecord,
        sortOrder,
        setSortOrder,
        sortBy,
        setSortBy,
        filter,
        setFilter,
        dialog,
        setdialogs,
        isEdit,
        setIsEdit,
        id,
        setId,
        formData,
        setFormData,
        yes,
        setYes,
        errors,
        setErrors,
      }}
    >
      {props.children}
    </DetailsPageContext.Provider>
  );
};

export default DetailsPageState;
