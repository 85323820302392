import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from "../firebase";
import { EMAIL, LOGIN, PASSWORD, Role, TYPE_FELDS } from "../constants";
import { LoginInput } from "../reuseable/Inputs";
import { SubmitTypeButton } from "../reuseableComponents/buttons";
import "../assets/css/Login.css";
import "react-toastify/dist/ReactToastify.css";
import { IsLoadingResponse, ToastMessage } from "../utils/Helper";
import { Form } from "reactstrap";
import { useMutation } from "react-query";
import { useRef, useEffect } from "react";
import {
  loginUserSchema,
} from "../utils/validationSchema";
import { collection, getDocs, where } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";

const Login = () => {
  const history = useNavigate();
  const [errors, setErrors] = useState({});
  const isLogging = localStorage.getItem("authToken");
  const {
    user,
    data,
    db_read
  } = UserAuth();



  const [formData, setformData] = useState({
    email: null,
    password: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setformData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const { mutate: Login, isLoading: LoginLoading } = useMutation((formData) => {
    // Login_(formData, history)
    localStorage.setItem("authToken", "Bearer hsdjafhdks-hfkshfd-jhsadkjfh-kjsdhfkh-sdf-hksdhf-kjsdhf-jjhka-sdhfdshf-jsdf");
    localStorage.setItem("role", "1");
    localStorage.setItem("loginName", "Admin");
    "1" === Role.Store_Admin ||
      "1" === Role.Delivery_Boy
      ? history("/")
      : history("/");
    ToastMessage(200, "Login Successful.");
  }
  );

  const LoginToWebsite = async (e) => {
    e.preventDefault();
    try {
      let schema = loginUserSchema();
      await schema.validate(formData, { abortEarly: false });

      // login usign firebase account
      await signInWithEmailAndPassword(auth, formData.email, formData.password).then(function (value) {
        //Logged in
        let authToken = value.user.accessToken;
        let role = value.user.accessToken;
        let userId = value.user.uid;
        let loginName = value.user.email;

        // console.log("data_current_user", data);

        getDocs(collection(db, "gallery"))
          .then((querySnapshot) => {
            // here data get
            if (querySnapshot?.docs.find((elem) => elem.id === userId).data()?.role?.roleType === "Admin") {
              let userData = querySnapshot?.docs.find((elem) => elem.id === userId).data();
              console.log("data_current_user", userData);

              localStorage.setItem("authToken", authToken);
              localStorage.setItem("role", "1");
              localStorage.setItem("userId", userId);
              localStorage.setItem("loginName", "Admin");
              localStorage.setItem("userName", userData.name);

              // get role from firebase account
              "1" === Role.Store_Admin
                ? history("/")
                : history("/");

              ToastMessage(200, "Login Successful.");
            } else {
              ToastMessage(400, "Please Enter Valid Credentials.");
            }

          }).catch((error) => {
            ToastMessage(400, "Please Enter Valid Credentials.");
          });

      }).catch(function (error) {
        ToastMessage(400, error.message);
      });

      // Login(formData);
    } catch (error) {
      const validationErrors = {};
      error.inner.forEach((validationError) => {
        validationErrors[validationError.path] = validationError.message;
      });
      setErrors(validationErrors);
      if (Object.keys(validationErrors)[0]) {
        const inputField = document.getElementsByName(Object.keys(validationErrors)[0]);
        inputField[0].focus();
      }
    }

    // if (formData.phone.length !== 0 && formData.password.length !== 0) {

    // }

  };

  useEffect(() => {
    if (isLogging !== null) {
      history("/");
    }
  });


  return (
    <>
      <IsLoadingResponse isLoading={LoginLoading} />
      <div className="login_background">
        <div className="login_main">
          <div className="login_center_container">
            <div className="login_content_container">
              <div>
                <div className="header">
                  <div>{LOGIN}</div>
                  <span>Please enter your details</span>
                </div>
                <Form onSubmit={(e) => LoginToWebsite(e)}>
                  <div className="flex flex-column">
                    <LoginInput
                      Label={EMAIL}
                      type={TYPE_FELDS.text}
                      name="email"
                      value={formData.email ? formData.email : ""}
                      onChange={handleChange}
                    />
                    {errors.email && (
                      <span className="error-validation">{errors.email}</span>
                    )}

                    <LoginInput
                      Label={PASSWORD}
                      type={TYPE_FELDS.password}
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <span className="error-validation">{errors.password}</span>
                    )}

                    <div className="w-full mt-3">
                      <SubmitTypeButton name="Submit" onclick={() => signInWithEmailAndPassword(formData.email, formData.password)} />
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
