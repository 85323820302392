import React, { Fragment, useContext, useState } from "react";
import AWS from 'aws-sdk';
import { Col, Form, Row } from "react-bootstrap";
import Breadcrumb from "../../../reuseable/Breadcrumb/Breadcrumb";
import {
  DASHBOARD,
  E_COMMERCE,
  SLACE,
} from "../../../reuseable/Breadcrumb/BreadcrumbConstant";
import { useMutation, useQuery } from "react-query";
import { ConfirmPassword, CreateUser, DashboardRedirect, DialCode, Email, Name, PasswordLabel, Phone, Submit, TYPE_FELDS, TypeNumber, optionsDialCode } from "../../../constants";
import { DashboardSkeleton } from "../../../utils/DataTabelSkelaton";
import { auth } from "../../../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { IsLoadingResponse, ToastMessage } from "../../../utils/Helper";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import backGroundImage from "../../../assets/Images/effect3.svg"
import { Dialog } from "primereact/dialog";
import UserContext from "../../../context/UserContext/UserContext";
import { CardBody } from "reactstrap";
import { FormDataFeild } from "../../../reuseable/Inputs";
import UserCreateFormPopup from "../Super_Admin/User/UserCreateFormPopup";


const Dashboard = (props) => {
  const {
    setFormData,
    isEdit,
    setdialogs,
    formData,
    dialog,
    setIsEdit,
    errors,
    setErrors,
    setFilterDrop,
  } = useContext(UserContext);

  const { refetch } = props;

  const bucketName = process.env.REACT_APP_WASABI_BUCKET_NAME;
  const s3 = new AWS.S3({
    region: process.env.REACT_APP_WASABI_REGION,
    endpoint: process.env.REACT_APP_WASABI_BUCKET_ENDPOINT,
    credentials: {
      accessKeyId: process.env.REACT_APP_WASABI_ACCESS_KEY_ID,
      secretAccessKey: process.env.REACT_APP_WASABI_ACCESS_KEY
    }
  });
  AWS.config.update({
    region: process.env.REACT_APP_WASABI_REGION,
    accessKeyId: process.env.REACT_APP_WASABI_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_WASABI_ACCESS_KEY,
  });



  //code for list folders in side folders
  var path_to_folder = 'AmolPhotography/vishalCreateInBucket/Test/';
  var params = {
    Bucket: bucketName,
    Prefix: path_to_folder
  };
  s3.listObjects(params, function (err, data) {
    if (err) {
      // console.log('Could not load objects from S3', err);
    } else {
      // debugger
      // console.log('Loaded ' + data.Contents.length + ' items from S3');
    }
  });

  const emptyData = () => {
    setFormData({
      parentId: localStorage.getItem("userId"),
      name: null,
      dialCode: { code: "+91", name: "+91" },
      phone: null,
      mobileno: null,
      userID: null,
      role: { roleID: 1, roleType: "User" },
      email: null,
      password: null,
      createdDate: null,
    });
    setFilterDrop((prev) => {
      return { ...prev, storeFilter: "" };
    });
  };

  /**
     * Open Popup and Close Popup User
     */
  const handleOpenandClosePopup = (condition) => {
    setIsEdit(true);
    if (condition) {
      setdialogs(condition);
      emptyData();
      setErrors({});
    } else {
      setdialogs(condition);
      emptyData();
      setErrors({});
    }
  };

  /**
  *
  * @param {get {value} and {name}} e
  * input value change
  */

  // const handleChangeOrgAdmin = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prev) => {
  //     return {
  //       ...prev,
  //       [name]: value,
  //     };
  //   });
  // };


  const search = (e) => { };

  let { isLoading: getDashboardLoader } = useQuery();

  return (
    <Fragment>
      {/* <Breadcrumb name={DASHBOARD} slace={SLACE} name1={E_COMMERCE} /> card-div grid-nogutter border*/}
      <Breadcrumb name={DASHBOARD} />

      <div className="grid " >
        <Row>
          <Col md="12 mb-12" onClick={() => handleOpenandClosePopup(true)}>
            {/* <Link to={elem.redirect}> */}
            <Card title="Add Users" className="p-3 text-white h-6rem border-round m-0 bg-center bg-cover bg-no-repeat bg-purple-400 cursor" style={{ backgroundImage: `url(${backGroundImage})` }}>
              <p className="m-0">
              </p>
            </Card>
            {/* </Link> */}
          </Col>
        </Row>
      </div>

      {dialog && (
        <UserCreateFormPopup
          handleOpenandClosePopup={handleOpenandClosePopup}
          refetch={refetch}
          emptyData={emptyData}
        />
      )}

    </Fragment>
  );
};

export default Dashboard;
