import { Delete, Edit } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";
import moment from "moment";
import { InputSwitch } from "primereact/inputswitch";
import React, { Fragment, useContext } from "react";
import UserContext from "../../../../context/UserContext/UserContext";
import { TIME_ZONE, optionsDialCode } from "../../../../constants";
import { useMutation } from "react-query";
import {
  DELETE_USER,
  Enable_Disable_User,
} from "../../../../Apis/userApi/UserApi";
import { IsLoadingResponse } from "../../../../utils/Helper";
import DataTabel from "../../../../reuseable/DataTabel";
import { TABEL_UNIQUE_ID } from "../../../../constants/RoutingConstants/RoutingConstants";
import SweetConfirmation from "../../../../reuseable/sweetConfirmation";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../../firebase";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

const UserAlbumDataTabelContainer = (props) => {
  const {
    filter,
    setFilter,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    setFormData,
    setdialogs,
    setOrgId,
    setIsEdit,
    setSortBy,
    setSortOrder,
    sortOrder,
    tempFolder,
    setTempFolder,
    setFolder,
    folder,
  } = useContext(UserContext);
  const { isLoading, data, handleOpenandClosePopup, refetch } = props;

  const GetUserDetails = (userId) => {
    // userDetails(userId);
    setOrgId(userId);
    setIsEdit(false);
  };

  /**
   * delete user
   */
  const { mutate: userDelete, isLoading: isLoadingDelete } = useMutation(
    (userId) => DELETE_USER(userId, refetch)
  );

  const DeleteUser = async (userId) => {
    let { isConfirmed } = await SweetConfirmation();
    if (isConfirmed) {
      userDelete(userId);
    }
  };

  /**
   * enable / disable user
   */

  const { mutate: statusChangeMutate } = useMutation((formData) =>
    Enable_Disable_User(formData, refetch)
  );

  const handleUserStatusChange = (userId, status) => {
    let formDate = {
      userId: userId,
      isActive: status ? false : true,
    };

    data.payload.data = data.payload.data.map((row) => {
      if (row.userId === userId) {
        return {
          ...row,
          isActive: status ? false : true,
        };
      } else {
        return row;
      }
    });
    statusChangeMutate(formDate);
  };

  /**
   *
   * @param {Sorting obj} col
   * Table Sorting
   */

  const sorting = (col) => {
    if (sortOrder === "") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("ASC");
    }
    if (sortOrder === "ASC") {
      let found = col.find((obj) => {
        return obj.field;
      });
      setSortBy(found.field);
      setSortOrder("DSC");
    }
    if (sortOrder === "DSC") {
      setSortBy();
      setSortOrder("");
    }
  };

  const defaultColumns = [
    {
      flex: 0.1,
      minWidth: 180,
      field: "name",
      headerName: "Name",
      renderCell: (params) => (params.row.imageName)
    },
    {
      flex: 0.1,
      minWidth: 180,
      field: "image",
      headerName: "Image",
      renderCell: (params) => (
        <div style={{ padding: "5px" }}>
          <img
            src={params.row.url}
            alt=""
            style={{
              width: "100px",
              height: "100px",
            }}
          />
        </div>
      )
    },
    {
      flex: 0.9,
      minWidth: 250,
      field: "comment",
      headerName: "Comment",
      renderCell: (params) => (params.row.comment ? params.row.comment : ""),
    },
  ];

  return (
    // <Fragment>
    <>
      <IsLoadingResponse isLoading={isLoadingDelete} />

      <DataTabel
        filter={filter}
        setFilter={setFilter}
        handleOpenandClosePopup={handleOpenandClosePopup}
        data={data}
        defaultColumns={defaultColumns}
        record={data?.length}
        pageSize={pageSize}
        page={page}
        setPageSize={setPageSize}
        setPage={setPage}
        sorting={sorting}
        isLoading={isLoading}
        sortOrder={sortOrder}
        setSortBy={setSortBy}
        setSortOrder={setSortOrder}
        id={TABEL_UNIQUE_ID.imageId}
        addButton={false}
        search={false}
      />
    </>
    // </Fragment>
  );
};

export default UserAlbumDataTabelContainer;
