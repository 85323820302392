import React, { useContext, useState } from "react";
import DataTabelSkelaton from "../utils/DataTabelSkelaton";
import { SearchFilterFeild } from "./Inputs";
import { Addnew, ORGANIZATION_TABEL } from "../constants";
import { DataGrid } from "@mui/x-data-grid";
import {
  DarkTabelMui,
  MuiTabelStyle,
} from "../constants/RoutingConstants/RoutingConstants";
import { tabelSorting } from "../utils/Helper";
import { CreateButtonOutline } from "../reuseableComponents/buttons";
import { Col, Row } from "react-bootstrap";
import CssContext from "../context/css_context/CssContext/CssContext";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Tooltip } from "@mui/material";
import FilterSidebar from "../layouts/filterSidebar";
import { useDispatch } from "react-redux";
import { filterSidebarOpen } from "../redux/slices/filter";

const DataTabel = (props) => {
  /**redux */
  const dispatch = useDispatch();

  const {
    filter,
    setFilter,
    handleOpenandClosePopup,
    data,
    defaultColumns,
    record,
    pageSize,
    page,
    setPageSize,
    setPage,
    isLoading,
    sortOrder,
    setSortBy,
    setSortOrder,
    id,
    type,
    filters,
    addButton,
    search = true,
  } = props;
  const { darkMode } = useContext(CssContext);
  const [filterside, setFilterSide] = useState(false);

  // const rowsWithIndex = data?.payload?.data.map((row, index) => ({
  //   ...row,
  //   index: index + 1, // Add the index property to each row
  // }));

  const rowsWithIndex = data?.map((row, index) => ({
    ...row,
    index: index + 1, // Add the index property to each row
  }));
  return (
    <>
      <div className="tabel-contant">
        <div className={`tabelContent ${darkMode ? "dark-tabel" : ""}`}>
          <div className="tabelSearch">
            <div className="p-datatable-header">
              {/* <div className="flex flex-wrap gap-2 align-items-center justify-content-between"> */}
              <Row>
                <Col md="6 mb-4">
                  {search && (
                    <SearchFilterFeild
                      filter={filter}
                      setFilter={setFilter}
                      type={type}
                      placeholder="Search"
                    />
                  )}
                </Col>
                <Col md="6 mb-4">
                  <div
                    style={{
                      display: "flex",
                      gap: "8px",
                      flexWrap: "wrap",
                      justifyContent: "end",
                    }}
                  >
                    {addButton && (
                      <CreateButtonOutline
                        click={handleOpenandClosePopup}
                        name={Addnew}
                        variant="primary"
                        icon="pi-user-plus"
                      />
                    )}
                    {filters && (
                      <Tooltip title="Filters">
                        <IconButton
                          aria-label="edit"
                          onClick={() => dispatch(filterSidebarOpen())}
                        >
                          <FilterListIcon style={{ color: "#7366ff" }} />
                        </IconButton>
                      </Tooltip>
                    )}
                    {/* </div> */}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
          {isLoading ? (
            <DataTabelSkelaton itemArr={ORGANIZATION_TABEL} />
          ) : (
            data && (
              <DataGrid
                sx={darkMode ? DarkTabelMui : MuiTabelStyle}
                autoHeight
                rows={rowsWithIndex}
                getRowId={(row) => row[id]}
                columns={defaultColumns}
                rowsPerPageOptions={[10, 20]}
                pagination
                rowCount={+record}
                pageSize={+pageSize}
                page={page - 1}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                onPageChange={(newPageNo) => setPage(newPageNo + 1)}
                // paginationMode="server"
                disableRowSelectionOnClick
                // checkboxSelection={false}
                // sortingMode="server"
                onSortModelChange={(col) =>
                  tabelSorting(col, sortOrder, setSortBy, setSortOrder)
                }
              />
            )
          )}
        </div>
      </div>
      <FilterSidebar filterside={filterside} setFilterSide={setFilterSide} />
    </>
  );
};

export default DataTabel;
