import Login from "../Auth/Login";
import ErrorPage from "../components/ErrorPage";
// import BannerSlider from "../components/pages/Super_Admin/BannerSlider/BannerSlider";
// import BannersList from "../components/pages/Super_Admin/BannerSlider/BannersList";
// import BannerSliderCreateFormPopup from "../components/pages/Super_Admin/BannerSlider/BannerSliderCreateFormPopup";
// import BrandMaster from "../components/pages/Super_Admin/BrandMaster/BrandMaster";
// import CategoryMaster from "../components/pages/Super_Admin/CategoryMaster/CategoryMaster";
// import CategoryMasterCreateFormPopup from "../components/pages/Super_Admin/CategoryMaster/CategoryMasterCreateFormPopup";
// import DetailsPage from "../components/pages/Super_Admin/DetailsPage/DetailsPage";
// import OrderSetting from "../components/pages/Super_Admin/OrderSetting/OrderSetting";
// import ProductTagMaster from "../components/pages/Super_Admin/ProductTagMaster/ProductTagMaster";
// import ProductTagMasterCreateFormPopup from "../components/pages/Super_Admin/ProductTagMaster/ProductTagMasterCreateFormPopup";
// import Products from "../components/pages/Super_Admin/Products/Products";
// import ProductsCreateFormPopup from "../components/pages/Super_Admin/Products/ProductsCreateFormPopup";
// import ProductsStock from "../components/pages/Super_Admin/ProductsStock/ProductsStock";
// import ProductsStockCreateFormPopup from "../components/pages/Super_Admin/ProductsStock/ProductsStockCreateFormPopup";
// import Store from "../components/pages/Super_Admin/Store/Store";
import User from "../components/pages/Super_Admin/User/User";
// import Feature from "../components/pages/Super_Admin/Features/Feature";
// import Inquiry from "../components/pages/Super_Admin/Inquiry/Inquiry";
// import InquiryDetails from "../components/pages/Super_Admin/Inquiry/InquiryDetails";
// import RidersList from "../components/pages/Super_Admin/Rider/RidersList";
// import RiderCreateFormPopup from "../components/pages/Super_Admin/Rider/RiderCreateFormPopup";
// import OrdersList from "../components/pages/Super_Admin/Order/OrdersList";

export const REDIRECT_PATH = {
  DASHBOARD: "/",
  USER: "/user",
  BRAND_MASTER: "/brand",
  ORDERS: "/orders",
  PRODUCTS: "/products",
  PRODUCTS_Add: "/products/:productId",
  PRODUCTS_STOCK: "/products/stock",
  PRODUCTS_STOCK_Add: "/products/stock/:stockId",
  PRODUCTS_TAG: "/products/tag",
  PRODUCTS_TAG_Add: "/products/tag/:tagId",
  CATEGORY: "/category",
  CATEGORY_Add: "/category/:catId",
  STORE: "/store",
  ORDER_SETTING: "/order-setting",
  SLIDER: "/slider",
  BANNER: "/banner",
  INQUIRY_: "/inquiry",
  INQUIRY_DETAILS: "/inquiry/:inquiryId/details",
  SLIDER_Add: "/slider/:sliderBannerId",
  GUEST_HOUSE: "/guest-House",
  GUEST: "/guest/guestList",
  DETAILS_PAGE: "/details-page",
  FEATURE: "/feature",
  GUEST_ADD: "/guest/guestAdd",
  ROOM_CATEGORY: "/roomCategory",
  ROOM_FACILITY: "/facility",
  ROOMS: "/rooms",
  ROOMS_ALLOCATE: "/rooms-allocate",
  RIDER: "/rider",
  RIDER_Add: "/rider/:riderId",
  AUTH: "/login",
};

export const SuperAdminAccess = [
  {
    path: REDIRECT_PATH.USER,
    component: <User />,
  },
  // {
  //   path: REDIRECT_PATH.BRAND_MASTER,
  //   component: <BrandMaster />,
  // },
  // {
  //   path: REDIRECT_PATH.ORDERS,
  //   component: <OrdersList />,
  // },
  // {
  //   path: REDIRECT_PATH.PRODUCTS,
  //   component: <Products />,
  // },
  // {
  //   path: REDIRECT_PATH.PRODUCTS_Add,
  //   component: <ProductsCreateFormPopup />,
  // },
  // {
  //   path: REDIRECT_PATH.PRODUCTS_STOCK,
  //   component: <ProductsStock />,
  // },
  // {
  //   path: REDIRECT_PATH.PRODUCTS_STOCK_Add,
  //   component: <ProductsStockCreateFormPopup />,
  // },
  // {
  //   path: REDIRECT_PATH.PRODUCTS_TAG,
  //   component: <ProductTagMaster />,
  // },
  // {
  //   path: REDIRECT_PATH.PRODUCTS_TAG_Add,
  //   component: <ProductTagMasterCreateFormPopup />,
  // },
  // {
  //   path: REDIRECT_PATH.CATEGORY,
  //   component: <CategoryMaster />,
  // },
  // {
  //   path: REDIRECT_PATH.CATEGORY_Add,
  //   component: <CategoryMasterCreateFormPopup />,
  // },
  // {
  //   path: REDIRECT_PATH.STORE,
  //   component: <Store />,
  // },
  // {
  //   path: REDIRECT_PATH.ORDER_SETTING,
  //   component: <OrderSetting />,
  // },
  // {
  //   path: REDIRECT_PATH.SLIDER,
  //   component: <BannerSlider />,
  // },
  // {
  //   path: REDIRECT_PATH.BANNER,
  //   component: <BannersList />,
  // },
  // {
  //   path: `${REDIRECT_PATH.SLIDER_Add}`,
  //   component: <BannerSliderCreateFormPopup />,
  // },
  // {
  //   path: `${REDIRECT_PATH.DETAILS_PAGE}`,
  //   component: <DetailsPage />,
  // },
  // {
  //   path: `${REDIRECT_PATH.FEATURE}`,
  //   component: <Feature />,
  // },
  // {
  //   path: `${REDIRECT_PATH.INQUIRY_}`,
  //   component: <Inquiry />,
  // },
  // {
  //   path: `${REDIRECT_PATH.INQUIRY_DETAILS}`,
  //   component: <InquiryDetails />,
  // },
  // {
  //   path: REDIRECT_PATH.RIDER,
  //   component: <RidersList />,
  // },
  // {
  //   path: `${REDIRECT_PATH.RIDER_Add}`,
  //   component: <RiderCreateFormPopup />,
  // },
];

export const PublicAccess = [
  {
    path: REDIRECT_PATH.AUTH,
    component: <Login />,
  },
  {
    path: "*",
    component: <ErrorPage />,
  },
];
