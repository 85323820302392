import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router } from "react-router-dom";
import UserState from "./context/UserContext/UserState";
import LoaderState from "./context/LoaderContext/LoaderState";
import ClockLoader from "./reuseable/loader";
import { ToastContainer } from "react-toastify";
import { Fragment, Suspense, useEffect, useState } from "react";
import MobileViewDiv from "./components/pages/MobileViewDiv/MobileViewDiv";
import CssState from "./context/css_context/CssContext/CssState";
import Loader from "./layouts/loader";
import TempleMasterState from "./context/TempleMasterContext/TempleMasterState";
import Progressbar from "./reuseable/Progressbar";
import AllRoutes from "./Routes/AllRoutes";
import GuestHouseMasterState from "./context/GuestHoustMasterContext/GuestHouseMasterState";
import GuestMasterState from "./context/GuestMasterContext/GuestMasterState";
import RoomCategoryMasterState from "./context/RoomCategoryMasterContext/RoomCategoryMasterState";
import RoomFacilityState from "./context/RoomFacilityContext/RoomFacilityState";
import RoomsState from "./context/RoomsContext/RoomsState";
import BrandMasterState from "./context/BrandMasterContext/BrandMasterState";
import CategoryState from "./context/CategoryContext/CategoryState";
import StoreState from "./context/StoreContext/StoreState";
import ProductsStockState from "./context/ProductsStockContext/ProductsStockState";
import BannerSlider from "./context/BannerSliderContext/BannerSliderState";
import OrderSettingState from "./context/OrderSettingContext/OrderSettingState";
import ProductTagMasterState from "./context/ProductTagMasterContext/ProductTagMasterState";
import DetailsPageState from "./context/DetailsPageContext/DetailsPageState";
import FeatureState from "./context/FeatureContext/FeatureState";
import InquiryState from "./context/InquiryContext/InquiryState";
import ProductsContextState from "./context/ProductsContext/ProductsContextState";
import Rider from "./context/RiderContext/RiderState";
import OrderState from "./context/OrderContext/OrderState";
import { AuthContextProvider } from "./context/AuthContext";
function App() {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  const [maindivSidebar, setMainDivSidebar] = useState(false);

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <Fragment>
      <div className="all-component-wrapper">
        <Router basename="/">
          <CssState>
            <LoaderState>
              <Rider>
                <RoomsState>
                  <RoomFacilityState>
                    <RoomCategoryMasterState>
                      <GuestMasterState>
                        <GuestHouseMasterState>
                          <TempleMasterState>
                            <InquiryState>
                              <FeatureState>
                                <DetailsPageState>
                                  <ProductTagMasterState>
                                    <OrderSettingState>
                                      <BannerSlider>
                                        <ProductsStockState>
                                          <StoreState>
                                            <CategoryState>
                                              <ProductsContextState>
                                                <OrderState>
                                                  <BrandMasterState>
                                                    <UserState>
                                                      <AuthContextProvider>
                                                        <ClockLoader />
                                                        <ToastContainer />
                                                        <Progressbar />
                                                        <Suspense
                                                          fallback={<Loader />}
                                                        >
                                                          <AllRoutes
                                                            windowSize={
                                                              windowSize
                                                            }
                                                            setMainDivSidebar={
                                                              setMainDivSidebar
                                                            }
                                                            maindivSidebar={
                                                              maindivSidebar
                                                            }
                                                          />
                                                        </Suspense>
                                                        <MobileViewDiv />
                                                      </AuthContextProvider>
                                                    </UserState>
                                                  </BrandMasterState>
                                                </OrderState>
                                              </ProductsContextState>
                                            </CategoryState>
                                          </StoreState>
                                        </ProductsStockState>
                                      </BannerSlider>
                                    </OrderSettingState>
                                  </ProductTagMasterState>
                                </DetailsPageState>
                              </FeatureState>
                            </InquiryState>
                          </TempleMasterState>
                        </GuestHouseMasterState>
                      </GuestMasterState>
                    </RoomCategoryMasterState>
                  </RoomFacilityState>
                </RoomsState>
              </Rider>
            </LoaderState>
          </CssState>
        </Router>
      </div>
      {/* )} */}
    </Fragment>
  );
}

export default App;

function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}
