export const SERVICES = {
  E_COM_PUBLIC: "/e-comm/public",
  E_COM_ADMIN: "/e-comm/admin",
  E_COM: "/e-comm",
};

export const V1 = "/api/v1";

/**Auth And Public*/
export const LOGIN = `${SERVICES.E_COM_PUBLIC}${V1}/login`;
export const FILE = `${SERVICES.E_COM_PUBLIC}${V1}/file`;

/**Common End Points */
export const CRETE = "/create";
export const ENABLE_DISABLE = "/enableDisable";
export const DETAILS = "/detail";
export const CHECK_SLUG = "/checkSlugUrl";

/**End points */
export const DASHBOARD = "/dashboard";
export const USER = "/user";
export const BRAND_MASTER = "/brandMaster";
export const PRODUCTS = "/products";
export const PRODUCTS_STOCK = "/stock";
export const CATEGORY_MASTER = "/categoryMaster";
export const STORE = "/store";
export const DETAILS_PAGE = "/detailsPage";
export const FOR_ADD_UPDATE = "/forAddedUpdate";
export const IMAGE = "/image";
export const EXTRA = "/extra";
export const BANNER_SLIDER = "/bannerSlider";
export const GLOBAL_SETTING = "/globalSetting";
export const PRODUCTS_TAG_MASTER = "/tagMaster";
export const FEATURE = "/feature";
export const INQUIRY = "/inquiry";

/**Dashboard */

export const GET_ADMIN_DASHBOARD = `${SERVICES.E_COM_ADMIN}${V1}${DASHBOARD}`;

/**User */

export const GET_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;
export const CREATE_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}${CRETE}`;
export const ENABLE_DISABLE_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}${ENABLE_DISABLE}`;
export const GET_ADMIN_USER_DETAILS = `${SERVICES.E_COM_ADMIN}${V1}${USER}${DETAILS}`;
export const EDIT_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;
export const DELETE_ADMIN_USER = `${SERVICES.E_COM_ADMIN}${V1}${USER}`;

/**Brand Master */

export const GET_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}`;
export const CREATE_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}`;
export const ENABLE_DISABLE_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}${ENABLE_DISABLE}`;
export const GET_BRAND_MASTER_DETAILS = `${SERVICES.E_COM}${V1}${BRAND_MASTER}${DETAILS}`;
export const EDIT_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}`;
export const DELETE_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}`;
export const CHECK_SLUG_BRAND_MASTER = `${SERVICES.E_COM}${V1}${BRAND_MASTER}${CHECK_SLUG}`;

/**Products */

export const GET_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}`;
export const CREATE_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}`;
export const ENABLE_DISABLE_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}${ENABLE_DISABLE}`;
export const GET_PRODUCTS_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${DETAILS}`;
export const EDIT_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}`;
export const DELETE_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}`;
export const CHECK_SLUG_PRODUCTS = `${SERVICES.E_COM}${V1}${PRODUCTS}${CHECK_SLUG}`;

/**Products Image */

export const GET_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}`;
export const CREATE_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}`;
export const ENABLE_DISABLE_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}${ENABLE_DISABLE}`;
export const GET_IMAGE_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}${DETAILS}`;
export const EDIT_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}`;
export const DELETE_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}`;
export const CHECK_SLUG_IMAGE = `${SERVICES.E_COM}${V1}${PRODUCTS}${IMAGE}${CHECK_SLUG}`;

/**Products Extra */

export const GET_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}`;
export const CREATE_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}`;
export const ENABLE_DISABLE_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}${ENABLE_DISABLE}`;
export const GET_PRODUCTS_EXTRA_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}${DETAILS}`;
export const EDIT_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}`;
export const DELETE_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}`;
export const CHECK_SLUG_PRODUCTS_EXTRA = `${SERVICES.E_COM}${V1}${PRODUCTS}${EXTRA}${CHECK_SLUG}`;

/**Category */

export const GET_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}`;
export const CREATE_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}`;
export const ENABLE_DISABLE_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}${ENABLE_DISABLE}`;
export const GET_CATEGORY_DETAILS = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}${DETAILS}`;
export const EDIT_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}`;
export const DELETE_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}`;
export const CHECK_SLUG_CATEGORY = `${SERVICES.E_COM}${V1}${CATEGORY_MASTER}${CHECK_SLUG}`;

/**Store */

export const GET_STORE = `${SERVICES.E_COM}${V1}${STORE}`;
export const GET_STORE_FOR_ADD_UPDATE = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}${FOR_ADD_UPDATE}`;
export const CREATE_STORE = `${SERVICES.E_COM}${V1}${STORE}`;
export const ENABLE_DISABLE_STORE = `${SERVICES.E_COM}${V1}${STORE}${ENABLE_DISABLE}`;
export const GET_STORE_DETAILS = `${SERVICES.E_COM}${V1}${STORE}${DETAILS}`;
export const EDIT_STORE = `${SERVICES.E_COM}${V1}${STORE}`;
export const DELETE_STORE = `${SERVICES.E_COM}${V1}${STORE}`;
export const CHECK_SLUG_STORE = `${SERVICES.E_COM}${V1}${STORE}${CHECK_SLUG}`;

/**Order setting */

export const GET_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}`;
export const GET_ORDER_SETTING_FOR_ADD_UPDATE = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}${FOR_ADD_UPDATE}`;
export const CREATE_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}`;
export const ENABLE_DISABLE_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}${ENABLE_DISABLE}`;
export const GET_ORDER_SETTING_DETAILS = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}${DETAILS}`;
export const EDIT_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}`;
export const DELETE_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}`;
export const CHECK_SLUG_ORDER_SETTING = `${SERVICES.E_COM}${V1}${GLOBAL_SETTING}${CHECK_SLUG}`;

/**Orders */

export const GET_ORDER = `${SERVICES.E_COM}${V1}/order`;

/**Products Stock */

export const GET_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}`;
export const CREATE_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}`;
export const ENABLE_DISABLE_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}${ENABLE_DISABLE}`;
export const GET_PRODUCTS_STOCK_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}${DETAILS}`;
export const EDIT_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}`;
export const DELETE_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_STOCK}`;
export const CHECK_SLUG_PRODUCTS_STOCK = `${SERVICES.E_COM}${V1}${PRODUCTS}${CHECK_SLUG}`;

/**Products Tag Master */

export const GET_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}`;
export const CREATE_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}`;
export const ENABLE_DISABLE_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}${ENABLE_DISABLE}`;
export const GET_PRODUCTS_TAG_MASTER_DETAILS = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}${DETAILS}`;
export const EDIT_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}`;
export const DELETE_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${PRODUCTS_TAG_MASTER}`;
export const CHECK_SLUG_PRODUCTS_TAG_MASTER = `${SERVICES.E_COM}${V1}${PRODUCTS}${CHECK_SLUG}`;

/**Banner Slider */

export const GET_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}`;
export const CREATE_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}`;
export const ENABLE_DISABLE_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}${ENABLE_DISABLE}`;
export const GET_BANNER_SLIDER_DETAILS = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}${DETAILS}`;
export const EDIT_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}`;
export const DELETE_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}`;
export const CHECK_SLUG_BANNER_SLIDER = `${SERVICES.E_COM}${V1}${BANNER_SLIDER}${CHECK_SLUG}`;

/**Details Page */

export const GET_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}`;
export const CREATE_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}`;
export const ENABLE_DISABLE_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${ENABLE_DISABLE}`;
export const GET_DETAILS_PAGE_DETAILS = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}${DETAILS}`;
export const EDIT_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}`;
export const DELETE_DETAILS_PAGE = `${SERVICES.E_COM}${V1}${DETAILS_PAGE}`;

/**Feature */

export const GET_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}`;
export const CREATE_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}`;
export const ENABLE_DISABLE_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}${ENABLE_DISABLE}`;
export const GET_FEATURE_DETAILS = `${SERVICES.E_COM}${V1}${FEATURE}${DETAILS}`;
export const EDIT_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}`;
export const DELETE_FEATURE = `${SERVICES.E_COM}${V1}${FEATURE}`;

/**Feature */

export const GET_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}`;
export const CREATE_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}`;
export const ENABLE_DISABLE_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}${ENABLE_DISABLE}`;
export const GET_INQUIRY_DETAILS = `${SERVICES.E_COM}${V1}${INQUIRY}${DETAILS}`;
export const EDIT_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}`;
export const DELETE_INQUIRY = `${SERVICES.E_COM}${V1}${INQUIRY}`;

/**Guest House Master */

export const GET_GUEST_HOUSE_MASTER = `${SERVICES.E_COM}${V1}/guestHouse`;
export const CREATE_GUEST_HOUSE_MASTER = `${SERVICES.E_COM}${V1}/guestHouse`;
export const ENABLE_DESABLE_GUEST_HOUSE_MASTER = `${SERVICES.E_COM}${V1}/guestHouse/enableDisable`;
export const GET_GUEST_HOUSE_MASTER_DETAILS = `${SERVICES.E_COM}${V1}/guestHouse/detail`;
export const EDIT_GUEST_HOUSE_MASTER = `${SERVICES.E_COM}${V1}/guestHouse`;

/**Guest Master */

export const GET_GUEST_MASTER = `${SERVICES.E_COM}${V1}/guestMaster`;
export const CREATE_GUEST_MASTER = `${SERVICES.E_COM}${V1}/guestMaster`;
export const ENABLE_DESABLE_GUEST_MASTER = `${SERVICES.E_COM}${V1}/guestMaster/enableDisable`;
export const GET_GUEST_MASTER_DETAILS = `${SERVICES.E_COM}${V1}/guestMaster/detail`;
export const EDIT_GUEST_MASTER = `${SERVICES.E_COM}${V1}/guestMaster`;

/**City - state - country Master */

export const GET_CITY = `${SERVICES.E_COM}${V1}/cities`;
export const GET_STATE = `${SERVICES.E_COM}${V1}/states`;
export const GET_COUNTRY = `${SERVICES.E_COM}${V1}/countries`;

/**Room Category Master */

export const GET_ROOM_CATEGORY = `${SERVICES.E_COM}${V1}/roomCategory`;
export const CREATE_ROOM_CATEGORY = `${SERVICES.E_COM}${V1}/roomCategory`;
export const ENABLE_DESABLE_ROOM_CATEGORY = `${SERVICES.E_COM}${V1}/roomCategory/enableDisable`;
export const GET_ROOM_CATEGORY_DETAILS = `${SERVICES.E_COM}${V1}/roomCategory/detail`;
export const EDIT_ROOM_CATEGORY = `${SERVICES.E_COM}${V1}/roomCategory`;

/**Room Facility */

export const GET_FACILITY = `${SERVICES.E_COM}${V1}/roomFacility`;
export const CREATE_FACILITY = `${SERVICES.E_COM}${V1}/roomFacility`;
export const ENABLE_DESABLE_FACILITY = `${SERVICES.E_COM}${V1}/roomFacility/enableDisable`;
export const GET_FACILITY_DETAILS = `${SERVICES.E_COM}${V1}/roomFacility/detail`;
export const EDIT_FACILITY = `${SERVICES.E_COM}${V1}/roomFacility`;

/**Room */

export const GET_ROOMS = `${SERVICES.E_COM}${V1}/roomMaster`;
export const CREATE_ROOMS = `${SERVICES.E_COM}${V1}/roomMaster`;
export const ENABLE_DESABLE_ROOMS = `${SERVICES.E_COM}${V1}/roomMaster/enableDisable`;
export const GET_ROOMS_DETAILS = `${SERVICES.E_COM}${V1}/roomMaster/detail`;
export const EDIT_ROOMS = `${SERVICES.E_COM}${V1}/roomMaster`;

/**Room Allocation */

export const GET_ALLOCATION = `${SERVICES.E_COM}${V1}/roomAllocation`;
export const CREATE_ALLOCATION = `${SERVICES.E_COM}${V1}/roomAllocation`;
export const ENABLE_DESABLE_ALLOCATION = `${SERVICES.E_COM}${V1}/roomAllocation/enableDisable`;
export const GET_ALLOCATION_DETAILS = `${SERVICES.E_COM}${V1}/roomAllocation/detail`;
export const EDIT_ALLOCATION = `${SERVICES.E_COM}${V1}/roomAllocation`;

/**Rider */

export const GET_RIDER = `${SERVICES.E_COM}${V1}/riderMaster`;
export const GET_RIDER_DETAILS = `${SERVICES.E_COM}${V1}/riderMaster/detail`;
export const CREATE_RIDER = `${SERVICES.E_COM}${V1}/riderMaster`;
export const ENABLE_DISABLE_RIDER = `${SERVICES.E_COM}${V1}/riderMaster/enableDisable`;
export const EDIT_RIDER = `${SERVICES.E_COM}${V1}/riderMaster`;
export const EDIT_RIDER_LOCATION = `${SERVICES.E_COM}${V1}/riderMaster/location`;
export const DELETE_RIDER = `${SERVICES.E_COM}${V1}/riderMaster`;

/**Temple Master */
export const GET_TEMPLE_MASTER = `${SERVICES.E_COM}${V1}/templeTrust`;
export const CREATE_TEMPLE_MASTER = `${SERVICES.E_COM}${V1}/templeTrust`;
export const GET_TEMPLE_MASTER_DETAILS = `${SERVICES.E_COM}${V1}/templeTrust/detail`;
export const EDIT_TEMPLE_MASTER = `${SERVICES.E_COM}${V1}/templeTrust`;

/**Donar Master */
export const GET_DONAR_MASTER = `${SERVICES.E_COM}${V1}/personMaster`;
